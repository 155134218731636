
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap');

.CarouselCardHeader{
    margin-top: 5px !important;
}

.CarouselCard{
    height: 100% !important;
}

.CarouselCardImage{
    width: 100px !important;
    height: 100px !important;
}

.CarouselCardProductTitle{
    font-size: 0.6em !important;
    margin: 0px !important;
}

.CarouselCardAlternativeTitle{
    font-size: 1.9em !important;
    font-family: 'Rubik', sans-serif;
    color: #96281b !important;
}

.CarouselCardMainTitle{
    font-size: 1.9em !important;
    font-family: 'Rubik', sans-serif;
    color: #96281b !important;
}

.CarouselCardSubTitle{
    top: -1em !important;
    font-size: 0.9em !important;
    font-family: 'Rubik', sans-serif;
    color: #96281b !important;

}

.CarouselCardDollarSign{
    top: -1em !important;
    font-size: 0.7em !important;
    font-family: 'Rubik', sans-serif;
    color: #96281b !important;
}

.CarouselCardForLabel{
    top: -0.9em !important;
    font-size: 0.6em !important;
    font-family: 'Rubik', sans-serif;
    color: black !important;
}

.CarouselCardDescription{
    font-size: 0.6em !important;
}