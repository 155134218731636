@import url(https://fonts.googleapis.com/css2?family=Courgette&family=Dancing+Script&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Courgette&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap);
.ui.borderless.menu{
	background-color: white !important;
}

.menuItems{
	text-align: center !important;
	font-size: 15px !important;
}

.menuItemsImages{
	width: 45px !important;
}

.SiteHeaderMainLogo{
	width: 120px !important;
}

.PageHeader{
    font-family: 'Yellowtail', cursive !important;
    font-size: 1.7em !important;
}

.PageSubHeader{
    font-family: 'Courgette', cursive !important;
    font-size: 1.0em !important;
}
.AboutMainParagraph{
    font-family: 'Courgette', cursive !important;
    font-size: 1.3em !important;
}
.MessageBoardSegment{
    
}

.CarouselCardHeader{
    margin-top: 5px !important;
}

.CarouselCard{
    height: 100% !important;
}

.CarouselCardImage{
    width: 100px !important;
    height: 100px !important;
}

.CarouselCardProductTitle{
    font-size: 0.6em !important;
    margin: 0px !important;
}

.CarouselCardAlternativeTitle{
    font-size: 1.9em !important;
    font-family: 'Rubik', sans-serif;
    color: #96281b !important;
}

.CarouselCardMainTitle{
    font-size: 1.9em !important;
    font-family: 'Rubik', sans-serif;
    color: #96281b !important;
}

.CarouselCardSubTitle{
    top: -1em !important;
    font-size: 0.9em !important;
    font-family: 'Rubik', sans-serif;
    color: #96281b !important;

}

.CarouselCardDollarSign{
    top: -1em !important;
    font-size: 0.7em !important;
    font-family: 'Rubik', sans-serif;
    color: #96281b !important;
}

.CarouselCardForLabel{
    top: -0.9em !important;
    font-size: 0.6em !important;
    font-family: 'Rubik', sans-serif;
    color: black !important;
}

.CarouselCardDescription{
    font-size: 0.6em !important;
}
.StoreDepartmentMainHeader{
    font-family: 'Yellowtail', cursive !important;
    font-size: 1.6em !important;
}

.StoreDepartmentDescription{
    font-size: 0.8em !important;
    font-style: italic !important;
}

.StoreLocationsCard{
    height: 100% !important;
}
.CakeImage{
    width: 100px !important;
    height: 100px !important;
}

.CustomRadioButton{
    -webkit-transform: scale(1.3) !important;
            transform: scale(1.3) !important;
}

.CakeSizeHeader{
    font-size: 0.9em !important;
    margin: 0px !important;
}
.FooterDiv{
    width: 100% !important;
	margin-right: 0 !important;
	margin-left: 0 !important;
}
.MainDiv{
	max-width: 100% !important;
	margin: 0px !important;
}

.MainGreenColor{
	background-color: #445f3d !important;
}
