.CakeImage{
    width: 100px !important;
    height: 100px !important;
}

.CustomRadioButton{
    transform: scale(1.3) !important;
}

.CakeSizeHeader{
    font-size: 0.9em !important;
    margin: 0px !important;
}