.ui.borderless.menu{
	background-color: white !important;
}

.menuItems{
	text-align: center !important;
	font-size: 15px !important;
}

.menuItemsImages{
	width: 45px !important;
}

.SiteHeaderMainLogo{
	width: 120px !important;
}
