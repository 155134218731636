@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Dancing+Script&display=swap');

.PageHeader{
    font-family: 'Yellowtail', cursive !important;
    font-size: 1.7em !important;
}

.PageSubHeader{
    font-family: 'Courgette', cursive !important;
    font-size: 1.0em !important;
}