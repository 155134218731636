@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');

.StoreDepartmentMainHeader{
    font-family: 'Yellowtail', cursive !important;
    font-size: 1.6em !important;
}

.StoreDepartmentDescription{
    font-size: 0.8em !important;
    font-style: italic !important;
}

.StoreLocationsCard{
    height: 100% !important;
}